import React from 'react'
import './bp_intro2.css'
const Blogpage2 = () => {
  return (
  <>
<div className="bp_intro2_mainContainer">
  <div className="bp_intro2_flexContainer">
    <div className="bp_intro2_text">
      Hold on !! soon we will get best blogs for you
    </div>
  </div>
</div>
</> 
  )
}

export default Blogpage2