export const testemonialData = [
    {
        id:1,
        name:"Aayush Patel",
        university:"IU University Germany",
        message:"‟ining Beyond Bharat Consultancy was the turning point in my study abroad journey. Their meticulous visa guidance and personalized document preparation gave me an edge in my application to Oxford. I couldn’t have done it without their expert team!”",
        avatar:"https://img.freepik.com/free-photo/smiling-young-male-professional-standing-with-arms-crossed-while-making-eye-contact-against-isolated-background_662251-838.jpg?w=740&t=st=1704388659~exp=1704389259~hmac=77a88777567eb2ff30464a3a282f05b4268915492129391231fd35f8f3da3c8b"
    },
    {
        id:2,
        name:"Dishant Singh ",
        university:" Exter university, UK",
        message:"I was overwhelmed by the thought of applying to MIT. But the team at Beyond Bharat Consultancy made it achievable with their structured approach and comprehensive coaching. They truly are champions in what they do!”",
        avatar:"https://img.freepik.com/free-photo/successful-businessman_1098-18155.jpg?size=626&ext=jpg&ga=GA1.1.1344950147.1704388655&semt=ais"
    },
    {
        id:3,
        name:"Nidhi Jariwal",
        university:"Constoga college, Canada",
        message:"‟The attention to detail and support I received from Beyond Bharat Consultancy was exceptional. From choosing the right course to acing the visa interview, their expertise was evident. They turned my Canadian dream into a reality!”",
        avatar:"https://img.freepik.com/premium-photo/confidence-leads-success-portrait-young-woman-standing-office_590464-9747.jpg"
    },
    {
        id:4,
        name:"Fatima A.",
        university:"Technical University of Munich",
        message:"‟Studying in Germany seemed complex, but Beyond Bharat Consultancy demystified the process. Their knowledge about the blocked account setup and the German consulate procedures was invaluable. I am grateful for their dedication!”",
        avatar:"https://images.moneycontrol.com/static-mcnews/2023/02/Apsara-Iyer-Harvard-770x435.jpg?impolicy=website&width=1600&height=900"
    },
    {
        id:5,
        name:"Harsh Gupta",
        university:"University of toronto",
        message:"‟Navigating the complexities of studying at the University of Toronto felt daunting, but Beyond Bharat Consultancy simplified it all. Their expertise in handling the intricacies of the application process and providing valuable insights was invaluable. Grateful for their dedicated assistance!”",
        avatar:"https://img.freepik.com/free-photo/cheerful-indian-businessman-smiling-closeup-portrait-jobs-career-campaign_53876-129417.jpg?size=626&ext=jpg&ga=GA1.1.1344950147.1704388655&semt=ais"
    },
]