import React from "react";
import "./Germnay_data.css";
import { FaBookReader } from "react-icons/fa";
import { FaLanguage } from "react-icons/fa6";
import { FaUniversity } from "react-icons/fa";
import { RiFlightTakeoffFill } from "react-icons/ri";
import { MdSupportAgent } from "react-icons/md";
const UK_data = () => {
  return (
    <>
      <div className="g_data_mainContainer">
        <div className="g_data_subContainer">
          <div className="g_data_headText">Study in the UK</div>
          <div className="g_data_subText">
            Embarking on a journey to study in the United Kingdom is not just
            about acquiring an education; it's about immersing yourself in a
            rich cultural tapestry, steeped in academic excellence and
            innovation. At Beyond Bharat Consultancy, we specialize in turning
            your dream of studying in the UK into a tangible reality.
          </div>
          <div className="g_data_textDiv">
            <div className="g_data_headText">
              Why Choose the UK for Your Studies?
            </div>
            <div className="g_data_subText">
              GThe UK is a hub of academic prestige, home to some of the world's
              most renowned universities. From the historic halls of Oxford and
              Cambridge to the modern, cutting-edge campuses across the country,
              the UK offers a diverse range of educational experiences. Here,
              you'll gain qualifications that are respected globally, opening
              doors to international career opportunities.
            </div>
          </div>
          <div className="g_data_textDiv">
            <div className="g_data_headText">Our Expertise in UK Education</div>
            <div className="g_data_subText">
              Our team at Beyond Bharat Consultancy brings a wealth of knowledge
              about the UK education system. We have direct connections with a
              variety of UK universities, enabling us to provide you with
              up-to-date advice and exclusive insights. Whether you're aiming
              for an undergraduate degree, a postgraduate program, or a
              research-oriented course, we have the resources and know-how to
              guide you.
            </div>
          </div>
          <div className="g_data_cards_div">
            <div className="g_data_headText2">
              Tailored Guidance for Every Step
            </div>
            <div className="g_data_flexContainer">
              {/* div start */}
              <div className="g_data_flexDataContanier">
                <div className="g_flexcon1">
                  <FaBookReader />
                </div>
                <div className="g_flexcon2">
                  <div className="g_data_flexHead">Course Selection</div>
                  <div className="g_data_flexData">
                    Navigating the plethora of courses offered by UK
                    universities can be daunting. We simplify this process by
                    analyzing your academic background, career aspirations, and
                    personal interests to find the perfect course for you.
                  </div>
                </div>
              </div>
              {/* div end */}
              {/* div start */}
              <div className="g_data_flexDataContanier">
                <div className="g_flexcon1">
                  <FaLanguage />
                </div>
                <div className="g_flexcon2">
                  <div className="g_data_flexHead">Application Process</div>
                  <div className="g_data_flexData">
                    From the UCAS application to university-specific
                    requirements, we ensure that every aspect of your
                    application is handled with precision and care. Our
                    AI-powered tools complement our expertise, offering
                    personalized suggestions to enhance your application.
                  </div>
                </div>
              </div>
              {/* div end */}
              {/* div start */}
              <div className="g_data_flexDataContanier">
                <div className="g_flexcon1">
                  <FaUniversity />
                </div>
                <div className="g_flexcon2">
                  <div className="g_data_flexHead">Visa Assistance</div>
                  <div className="g_data_flexData">
                    Our team stays updated with the latest UK visa regulations,
                    ensuring a smooth and hassle-free visa application process.
                    We guide you through every requirement, preparing you for
                    interviews and helping with documentation.
                  </div>
                </div>
              </div>
              {/* div end */}
              {/* div start */}
              <div className="g_data_flexDataContanier">
                <div className="g_flexcon1">
                  <RiFlightTakeoffFill />
                </div>
                <div className="g_flexcon2">
                  <div className="g_data_flexHead">
                    Pre-Departure Preparation
                  </div>
                  <div className="g_data_flexData">
                    We don’t just stop at getting you admitted; we prepare you
                    for life in the UK. This includes cultural orientation,
                    accommodation assistance, and connecting you with a network
                    of other students embarking on a similar journey.
                  </div>
                </div>
              </div>
              {/* div end */}
            </div>
          </div>
          <div className="g_data_textDiv">
            <div className="g_data_headText">
              Beyond Academics - A Cultural Odyssey
            </div>
            <div className="g_data_subText">
              Studying in the UK is also about experiencing a vibrant,
              multicultural society. It's an opportunity to build a global
              network, explore historical landmarks, and engage with a mix of
              traditions and modern influences.
              <br />
              At Beyond Bharat Consultancy, we are committed to making your
              educational journey in the UK a memorable and enriching
              experience. Let us be the bridge that connects you to your
              academic and professional aspirations in the United Kingdom.
            </div>
          </div>
          {/* div end */}
          {/* Table Starts */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Process Step</th>
                  <th>What we will Do</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Course and College Shortlisting</td>
                  <td>
                    We will first understand your Profile and based on that, and
                    your interest, we will help you with shortlisting course and
                    college
                  </td>
                </tr>
                <tr>
                  <td>Document Preparation</td>
                  <td>
                    Compile Transcripts, employment certificate, SOP, LORs, Test
                    Results, and other necessary documents
                  </td>
                </tr>
                <tr>
                  <td>University / College Application</td>
                  <td>
                    Depending upon your eligibility and profile we apply to 4 –
                    5 colleges
                  </td>
                </tr>
                <tr>
                  <td>CAS Interview (Online)</td>
                  <td>
                    If you opt for a University / College that has interview
                    process, we will help you with questions as well as answers.
                  </td>
                </tr>
                <tr>
                  <td>Tuition fees</td>
                  <td>
                    If Funding is what you seek, then we can help you with loan
                    vendors, currency exchange, fund transfer etc.
                  </td>
                </tr>
                <tr>
                  <td>Medical</td>
                  <td>
                    We will help you with appointments and guide you through the
                    entire process
                  </td>
                </tr>
                <tr>
                  <td>CAS Letter</td>
                  <td>
                    After the due diligence and documentation, you will receive
                    your CAS letter
                  </td>
                </tr>
                <tr>
                  <td>Visa Filing </td>
                  <td>
                    Once all the documents and formalities are done, we will be
                    filing for visa
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Ready to Fly</td>
                  <td>
                  We will help you with Flight Tickets and accommodation too. 
                  </td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default UK_data;
